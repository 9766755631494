import React from 'react'
import styled from '@emotion/styled'
import { Icon, WYSIWYG } from '../ui'
import {
  usePricePackagesQuery,
  PricePackagesQueryHookResult,
} from '../../generated-models'
import { Flex } from 'rebass'

const PricingCard = styled(Flex)`
  border-radius: 12px;
  overflow: hidden;
  flex-direction: column;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  &.most-chosen {
    &::before {
      content: 'Meest gekozen';
      font-size: 12px;
      position: absolute;
      background: red;
      color: white;
      padding: 10px 40px;
      top: 27px;
      right: -44px;
      transform: rotate(45deg);
    }
  }
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.5);
  }
`

const ReadMore = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  cursor: pointer;
`

const PackageDetails = styled.div`
  background-color: white;
  padding: 24px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const PackageProperties = styled.div`
  background: linear-gradient(
    to bottom,
    ${({ theme }) => `${theme.colors.secondary}, ${theme.colors.primary}`}
  );
  color: white;
  padding: 24px;
  td {
    vertical-align: middle;
    padding-bottom: 10px;
    &:not(:first-of-type) {
      padding-left: 10px;
    }
    & > span {
      line-height: 1.25;
    }
  }
`

const PackageTitle = styled.span`
  display: block;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.primary};
`

const PackageSummary = styled.p`
  text-align: left;
  flex: 1 0 0;
`

const PackageDescription = styled.p`
  text-align: justify;
`

const PackagePrice = styled.span`
  &::before {
    content: '€';
    margin-right: 2px;
    vertical-align: super;
    font-size: 15px;
    font-weight: 400;
  }
  &::after {
    content: 'incl. BTW';
    font-size: 12px;
    margin-left: 4px;
  }
  font-weight: 600;
  font-size: 48px;
  display: block;
  margin-bottom: 24px;
`

const InfoModal = styled.div<{ open: boolean }>`
  position: relative;
  width: 75%;
  height: 50%;
  overflow: hidden;
  background: white;
  border-radius: 12px;
  transform: ${({ open }) => (open ? 'scale(1)' : 'scale(0)')};
  transition: transform 0.25s ease;
  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 50%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 80%;
    height: 80%;
  }
`

const InfoModalBody = styled.div`
  position: absolute;
  overflow: auto;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
`

const Close = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background: linear-gradient(
    to right,
    ${({ theme }) => `${theme.colors.secondary}, ${theme.colors.primary}`}
  );
  z-index: 2000;
  & > ${PackageTitle} {
    display: inline-block;
    color: white;
    margin: 0;
  }
  & > svg {
    float: right;
    cursor: pointer;
  }
  padding: 20px;
`

const ModalContainer = styled.div<{ open: boolean }>`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  opacity: ${({ open }) => (open ? 1 : 0)};
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.25s ease;
  z-index: 1000;
`

const PricePackage = (
  pricePackage: PricePackagesQueryHookResult['data']['pricePackages'][0]
) => {
  const [open, setOpen] = React.useState(false)
  const toggleOpen = () => {
    setOpen(!open)
  }
  return (
    <>
      <PricingCard
        flex={['0 0 100%', '0 0 30%']}
        margin={['20px 0']}
        onClick={toggleOpen}
        className={pricePackage.mostChosen ? 'most-chosen' : ''}
      >
        <PackageDetails>
          <PackageTitle>{pricePackage.name}</PackageTitle>
          <PackagePrice>{pricePackage.price}</PackagePrice>
          <PackageSummary>{pricePackage.summary}</PackageSummary>
          <ReadMore onClick={toggleOpen}>
            <span>Meer info</span>
          </ReadMore>
        </PackageDetails>
        <PackageProperties>
          <table>
            <tbody>
              {pricePackage.properties.map((property, i) => (
                <tr key={i}>
                  <td>
                    <div style={{ width: 24 }}>
                      <Icon
                        icon="check-mark"
                        color="white"
                        style={{ transform: 'translateY(3px)' }}
                      />
                    </div>
                  </td>
                  <td>
                    <span>{property}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </PackageProperties>
      </PricingCard>
      <ModalContainer open={open} onClick={toggleOpen}>
        <InfoModal open={open}>
          <Close>
            <PackageTitle>{pricePackage.name}</PackageTitle>
            <Icon
              icon="plus"
              color="white"
              style={{ transform: 'rotate(45deg)' }}
            />
          </Close>
          <InfoModalBody>
            <PackageDetails>
              <PackagePrice>{pricePackage.price}</PackagePrice>
              <PackageDescription>
                <WYSIWYG
                  dangerouslySetInnerHTML={{ __html: pricePackage.description }}
                />
              </PackageDescription>
            </PackageDetails>
          </InfoModalBody>
        </InfoModal>
      </ModalContainer>
    </>
  )
}

const Pricing = () => {
  const { loading, error, data } = usePricePackagesQuery()
  if (loading || error) return null
  return (
    <Flex flexWrap="wrap" justifyContent="space-between">
      {data.pricePackages.map((pricepackage, i) => (
        <PricePackage key={i} {...pricepackage} />
      ))}
    </Flex>
  )
}

export default Pricing
