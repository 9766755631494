import styled from '@emotion/styled'
import * as React from 'react'
import { Icon } from '../ui'
import { IconProps } from '../ui/Icon'
import { Flex, Heading } from 'rebass'
import { useThemeUI } from 'theme-ui'

const Description = styled.p`
  text-align: left;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
`

const DescriptionWrapper = styled(Flex)<{ isVisible?: boolean }>`
  flex-direction: column;
  justify-content: center;
  border: 1px solid #333;
  border-radius: 5px;
  padding: 20px;
  background: white;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: translateY(${({ isVisible }) => (isVisible ? 0 : -10)}%);
  transition: transform 0.4s ease;
`

const IconPlacement = styled.div`
  position: absolute;
  display: block;
  transition: all 5s ease-in;
  & > svg {
    fill: ${({ theme }) => theme.colors.primary};
    transform: scale(2);
    @media (max-width: ${({ theme }) => `${theme.breakpoints[2]}`}) {
      transform: scale(1.5);
    }
  }
`

const Step = styled(Flex)<{
  isVisible?: boolean
  delay?: number
  active: boolean
}>`
  z-index: 100;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 60px;
    height: 60px;
  }
  cursor: pointer;
  ${({ active, theme }) =>
    !active &&
    `
    &:hover, &:focus {
      border: 3px solid ${theme.colors.primary};
    }
  `}
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: translateY(${({ isVisible }) => (isVisible ? 0 : -10)}%);
  transition: opacity 0.4s ease-out ${({ delay }) => delay}s,
  transform 0.4s ease-out ${({ delay }) => delay}s;
  ${({ active, theme }) =>
    active &&
    `
    box-shadow: 0px 0px 5px #222;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      width: 0;
      height: 0;
      border: 15px solid transparent;
      border-bottom-color: white;
      border-top: 0;
      margin-top: 85px;
      @media(max-width: ${theme.breakpoints[1]}) {
        border: 10px solid transparent;
        border-right-color: white;
        border-left: 0;
        margin-top: 50%;
        transform: translatey(-50%);
        margin-left: 37px;
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 0;
      height: 0;
      border: 15px solid transparent;
      border-bottom-color: black;
      border-top: 0;
      margin-top: 85px;
      transform: scale(1.08);
      transform-origin: bottom;
      @media(max-width: ${theme.breakpoints[1]}) {
        border: 10px solid transparent;
        border-right-color: black;
        border-left: 0;
        margin-top: 50%;
        transform: translatey(-50%) scale(1.08);
        margin-left: 37px;
        transform-origin: right;
      }
    }
  `}
`

const Divider = styled.span`
  flex-grow: 1;
  border-bottom: 1px solid black;
  height: 0;
  margin: 5px;
`

export interface Step extends IconProps {
  name: string
  description: string
}

const ProgressBar: React.SFC<{ steps: Step[]; isVisible?: boolean }> = ({
  steps,
  isVisible,
}) => {
  const [activeStep, setActiveStep] = React.useState(0)
  const { theme } = useThemeUI() as any
  return (
    <Flex
      mt={32}
      alignItems={['flex-start', 'flex-start', 'stretch']}
      flexDirection={['row', 'row', 'column']}
    >
      <Flex
        flexDirection={['column', 'column', 'row']}
        alignItems="center"
        justifyContent="space-evenly"
        minWidth={80}
      >
        {steps.map((step, i) => (
          <React.Fragment key={i}>
            <Step
              isVisible={isVisible}
              delay={i * 0.2}
              onClick={() => setActiveStep(i)}
              active={activeStep === i}
              style={{
                backgroundColor: activeStep === i && theme.colors.primary,
              }}
            >
              <IconPlacement>
                <Icon
                  color={activeStep === i ? 'white' : step.color}
                  icon={step.icon}
                />
              </IconPlacement>
            </Step>
            {i !== steps.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Flex>
      <DescriptionWrapper
        minHeight={[0]}
        marginTop={[null, null, 20]}
        lang="nl"
        isVisible={isVisible}
      >
        <Heading fontSize={2} mb={16}>
          {steps[activeStep].name}
        </Heading>
        <Description>{steps[activeStep].description}</Description>
      </DescriptionWrapper>
    </Flex>
  )
}

export default ProgressBar
