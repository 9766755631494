import * as React from 'react'
import styled from '@emotion/styled'
import Carousel from '../Carousel'
import { Text, Image } from 'rebass'
import { useFeaturedCarsQuery } from '../../generated-models'
import numberWithCommas from '../../utils/numberWithCommas'
import { useThemeUI } from 'theme-ui'

const CarBackgroundImage = styled.div`
  transition: all 0.3s ease;
  width: 100%;
  height: 100%;
  background-color: black; /* fallback color */
  background-position: center;
  background-size: cover;
`

const CarCard = styled.div`
  position: relative;
  height: 500px;
  border-radius: 3px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    height: 300px;
  }
  &:hover,
  &:focus {
    & > ${CarBackgroundImage} {
      transform: scale(1.1);
    }
  }
`

const CarDetails = styled.h3`
  position: absolute;
  bottom: 0;
  color: white;
  left: 0;
  padding: 20px;
`

const CarLogo = styled(Image)`
  position: absolute;
  top: 0;
  right: 0;

  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.7));
`

const Prices = styled.div`
  color: white;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  tr {
    height: 20px;
    @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
      font-size: 14px;
    }
    & > td:last-child {
      text-align: right;
      &::before {
        content: '€';
      }
    }
  }
`

const RecentlyImported = () => {
  const { loading, error, data } = useFeaturedCarsQuery()
  const { theme } = useThemeUI() as any
  return (
    !loading &&
    !error && (
      <>
        <Carousel spacing={10} breakPoints={{ [theme.breakpoints[1]]: 2 }}>
          {data.featuredCars.map((car) => (
            <CarCard key={car.id}>
              <CarBackgroundImage
                style={{
                  backgroundImage: `linear-gradient(to top left, rgba(0,0,0,.25), rgba(0,0,0,.5)), url(${
                    car.images && car.images?.[0]?.location
                  })`,
                }}
              />
              <Prices>
                <table>
                  <tbody>
                    <tr>
                      <td>Prijs</td>
                      <td>{numberWithCommas(car.carPrice)}</td>
                    </tr>
                    <tr>
                      <td>Besparing*</td>
                      <td>{numberWithCommas(car.saving)}</td>
                    </tr>
                  </tbody>
                </table>
              </Prices>
              <CarDetails>{`${car.brand.name} ${car.model}`}</CarDetails>
              <CarLogo
                maxHeight={[86, 130]}
                p={[10, 20]}
                alt="Merk logo"
                src={car.brand.logo.location}
              />
            </CarCard>
          ))}
        </Carousel>
        <Text p={10}>
          * Ten opzichte van een vergelijkbare auto in Nederland.
        </Text>
      </>
    )
  )
}

export default RecentlyImported
