import React from 'react'
import { Formik } from 'formik'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import styled from '@emotion/styled'
import * as Yup from 'yup'
import Button from '../ui/Button'
import TextArea from '../ui/TextArea'
import TextInput from '../ui/TextInput'
import {
  useRequestBpmIndicationMutation,
  useRequestSearchMutation,
} from '../../generated-models'

const Container = styled.div`
  margin: 30px 0;
  color: white;
`

const StyledTabs = styled(Tabs)`
  box-shadow: 0 2px 20px 2px rgba(0, 0, 0, 0.5);
` as any

StyledTabs.tabsRole = 'Tabs'

const StyledTabList = styled(TabList)`
  margin: 0;
  display: flex;
` as any

StyledTabList.tabsRole = 'TabList'

const StyledTab = styled(Tab)`
  display: flex;
  flex-grow: 1;
  border: 1px solid white;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 15px;
  padding-left: 30px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.primaryDark};
  align-items: center;
  &:first-child {
    border-right: none;
  }

  &.react-tabs__tab--selected {
    border-bottom: none;
    background-color: ${({ theme }) => theme.colors.primary};
  }

  &.disabled {
    color: #e0e0e0;
    cursor: not-allowed;
  }
` as any

StyledTab.tabsRole = 'Tab'

const StyledTabPanel = styled(TabPanel)`
  display: none;
  padding: 30px;
  border: 1px solid white;
  border-top: none;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  background-color: ${({ theme }) => theme.colors.primary};
  &.selected {
    display: block;
  }
` as any

StyledTabPanel.tabsRole = 'TabPanel'

interface BpmFormProps {
  bpmIndicationBody: string
  searchRequestBody: string
}

const BpmForm: React.SFC<BpmFormProps> = props => {
  const [requestBpmIndication] = useRequestBpmIndicationMutation()
  const [requestSearch] = useRequestSearchMutation()
  return (
    <Container>
      <StyledTabs>
        <StyledTabList>
          <StyledTab>Gratis BPM Indicatie</StyledTab>
          <StyledTab>Zoekopdracht</StyledTab>
        </StyledTabList>
        <StyledTabPanel selectedClassName="selected">
          <p>{props.bpmIndicationBody}</p>
          <Formik
            initialValues={{ link: '', email: '', name: '', telephone: '' }}
            onSubmit={async (
              values,
              { setSubmitting, setStatus, resetForm }
            ) => {
              try {
                await requestBpmIndication({ variables: values })
                setSubmitting(false)
                setStatus({ success: true })
                setTimeout(() => resetForm(), 4000)
              } catch (e) {
                throw new Error(e)
              }
            }}
            validationSchema={Yup.object().shape({
              link: Yup.string()
                .url('Ongeldige link.')
                .required('Advertentie link is verplicht.'),
              name: Yup.string()
                .min(2, 'Voer een naam in langer dan 2 karakters.')
                .required('Naam is verplicht.'),
              email: Yup.string()
                .email('Voer een geldige email in.')
                .required('Email is verplicht.'),
              telephone: Yup.string().required('Telefoonnummer is verplicht.'),
            })}
          >
            {({ errors, status, handleSubmit, isSubmitting, submitCount }) => (
              <form autoComplete="new-password" onSubmit={handleSubmit}>
                <TextInput
                  name="name"
                  placeholder="Naam"
                  fieldProps={{ icon: 'person', color: 'white' }}
                />
                <TextInput
                  name="email"
                  placeholder="Email adres"
                  fieldProps={{ icon: 'mail', color: 'white' }}
                />
                <TextInput
                  name="telephone"
                  placeholder="Telefoonnummer"
                  fieldProps={{ icon: 'phone', color: 'white' }}
                />
                <TextInput
                  name="link"
                  placeholder="Link naar advertentie"
                  fieldProps={{ icon: 'link', color: 'white' }}
                />
                <Button
                  loading={isSubmitting}
                  success={(status || {}).success}
                  disabled={submitCount > 0 && Object.keys(errors).length > 0}
                >
                  Verstuur
                </Button>
              </form>
            )}
          </Formik>
        </StyledTabPanel>
        <StyledTabPanel selectedClassName="selected">
          <p>{props.searchRequestBody}</p>
          <Formik
            initialValues={{ note: '', email: '', name: '', telephone: '' }}
            onSubmit={async (
              values,
              { setSubmitting, setStatus, resetForm }
            ) => {
              try {
                await requestSearch({ variables: values })
                setSubmitting(false)
                setStatus({ success: true })
                setTimeout(() => resetForm(), 4000)
              } catch (e) {
                throw new Error(e)
              }
            }}
            validationSchema={Yup.object().shape({
              note: Yup.string().required(
                'Een beschrijving van de gezochte auto is verplicht'
              ),
              name: Yup.string()
                .min(2, 'Voer een naam in langer dan 2 karakters.')
                .required('Naam is verplicht.'),
              email: Yup.string()
                .email('Voer een geldige email in.')
                .required('Email is verplicht.'),
              telephone: Yup.string().required('Telefoonnummer is verplicht.'),
            })}
          >
            {({ errors, status, handleSubmit, isSubmitting }) => (
              <form autoComplete="new-password" onSubmit={handleSubmit}>
                <TextInput
                  name="name"
                  placeholder="Naam"
                  fieldProps={{ icon: 'person', color: 'white' }}
                />
                <TextInput
                  name="email"
                  placeholder="Email adres"
                  fieldProps={{ icon: 'mail', color: 'white' }}
                />
                <TextInput
                  name="telephone"
                  placeholder="Telefoonnummer"
                  fieldProps={{ icon: 'phone', color: 'white' }}
                />
                <TextArea
                  name="note"
                  placeholder="Beschrijving"
                  fieldProps={{ icon: 'message', color: 'white' }}
                />
                <Button
                  loading={isSubmitting}
                  success={(status || {}).success}
                  disabled={Object.keys(errors).length > 0}
                  onClick={() => handleSubmit()}
                >
                  Verstuur
                </Button>
              </form>
            )}
          </Formik>
        </StyledTabPanel>
      </StyledTabs>
    </Container>
  )
}

export default BpmForm
