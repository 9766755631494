import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import IndexPage from 'templates/IndexPage'
import Layout from 'layouts'
import { Helmet } from 'react-helmet'

export default () => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        frontmatter {
          banner {
            title
            subTitle
            backgroundImage
            bpmForm {
              bpmIndicationBody
              searchRequestBody
            }
          }
          intro
          steps {
            icon
            name
            description
          }
          features {
            title
            description
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Helmet
        title="Import, verkoop en cogsignatie van auto's"
        meta={[
          {
            name: 'og:title',
            content: '',
          },
        ]}
      />
      <IndexPage {...data} />
    </Layout>
  )
}
