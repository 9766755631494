import React from 'react'
import OnScreen from 'react-on-screen'
import ReactMarkdown from 'react-markdown'
import useMode from '../utils/useMode'
import { rgba } from 'polished'
import { Section, Feature } from '../components/ui'
import { BPMForm, ProgressBar, RecentlyImported, Pricing } from '../components'
import { MarkdownRemarkFrontmatter } from 'graphqlTypes'
import { Flex, Box, Heading, Text } from 'rebass'
import { useThemeUI } from 'theme-ui'

export const IndexPageTemplate = (props: MarkdownRemarkFrontmatter) => {
  const [, setMode] = useMode()
  setMode('light')
  const { theme } = useThemeUI()
  return (
    <>
      <Flex
        sx={{
          backgroundImage: `linear-gradient(to bottom right, ${rgba(
            theme.colors.secondary,
            0.5
          )}, ${rgba(theme.colors.primary, 0.5)}), url(${
            props.banner.backgroundImage
          })`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
        py={100}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexDirection={['column', 'row']}
          variant="container"
        >
          <Box width={[1, 1 / 2]}>
            <OnScreen once>
              <Box>
                <Box>
                  <Heading as="h1" fontSize={[4, 5, 6]} color="muted">
                    {props.banner.title}
                  </Heading>
                </Box>
                <Box marginTop={30} width="100%">
                  <Heading as="h3" fontSize={[3, 3, 4]} color="muted">
                    {props.banner.subTitle}
                  </Heading>
                </Box>
              </Box>
            </OnScreen>
          </Box>
          <Box width={[1, 1 / 2, 1 / 2.5]}>
            <BPMForm
              bpmIndicationBody={props.banner.bpmForm.bpmIndicationBody}
              searchRequestBody={props.banner.bpmForm.searchRequestBody}
            />
          </Box>
        </Flex>
      </Flex>
      <Section
        sx={{
          p: {
            textAlign: 'justify',
          },
        }}
      >
        <Box>
          <ReactMarkdown source={props.intro} />
          <Heading mt={30} fontSize={[2, 3]}>
            Stappenplan bij het importeren
          </Heading>
          <OnScreen once partialVisibility>
            <ProgressBar steps={props.steps as any} />
          </OnScreen>
        </Box>
      </Section>
      <Section fluid title="Recent Geïmporteerd">
        <RecentlyImported />
      </Section>
      <Section type="secondary" title="Waarom PC Auto Import?">
        {props.features.map(({ description, title }, i) => (
          <Box width="100%" key={i}>
            <Feature
              title={title}
              description={<ReactMarkdown source={description} />}
              index={i}
            />
          </Box>
        ))}
      </Section>
      <Section id="pricing" title="Prijzen">
        <Text mb={20} sx={{ fontStyle: 'italic' }}>
          Drie situaties, drie pakketten.
        </Text>
        <Pricing />
      </Section>
    </>
  )
}

const IndexPage = (props: any) => {
  const { frontmatter } = props.markdownRemark
  return <IndexPageTemplate {...frontmatter} {...props} />
}

export default IndexPage
