import * as React from 'react'
import { useSwipeable, EventData } from 'react-swipeable'
import styled from '@emotion/styled'
import { Icon } from '../ui'
import { isClient } from '../../constants'
import { Box, BoxProps } from 'rebass'

const Wrapper = styled(Box)`
  width: 100%;
  overflow: hidden;
  position: relative;
`

const NextIconWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  z-index: 90;
  transform: translateY(-50%);
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    right: 5px;
  }
`

const CarouselSlot = styled.div<{
  order: number
  cardsToDisplay: number
  spacing: number
}>`
  flex: 1 0
    ${({ cardsToDisplay, spacing }) =>
      `calc((80% - ${spacing}px) / ${cardsToDisplay})`};
  margin-right: ${({ spacing }) => `${spacing}px`};
  order: ${({ order }) => order};
`

const CarouselContainer = styled.div<
  Pick<CarouselState, 'direction' | 'sliding' | 'position'> & {
    cardsToDisplay: number
    spacing: number
  }
>`
  display: flex;
  margin: 0;
  transition: ${({ sliding }) => (sliding ? 'none' : 'transform 1s ease')};
  transform: ${({ sliding, direction, cardsToDisplay }) => {
    if (!sliding) return `translateX(calc(80% / -${cardsToDisplay}))`
    if (direction === 'prev')
      return `translateX(calc(2 * 80% / -${cardsToDisplay}))`
    return `translateX(0)`
  }};
`

interface CarouselState {
  cardsToDisplay: number
  position: number
  direction: 'next' | 'prev'
  sliding: boolean
}

interface CarouselProps extends Omit<BoxProps, 'css'> {
  breakPoints?: { [width: string]: number }
  spacing?: number
}

const Carousel: React.SFC<CarouselProps> = ({
  children,
  breakPoints,
  spacing = 0,
  ...props
}) => {
  const breakPointResolver = () => {
    if (breakPoints && isClient) {
      const sortedBreakPoints = Object.entries(breakPoints).sort(
        (a, b) => parseInt(a[0]) - parseInt(b[0])
      )
      const itemsToDisplay: number = sortedBreakPoints.reduce((acc, curr) => {
        if (parseInt(curr[0], 10) * 16 <= window.innerWidth) return curr[1]
        return acc
      }, 1)
      return itemsToDisplay
    }
    return 1
  }

  const [slideState, setSlideState] = React.useState<CarouselState>({
    cardsToDisplay: null,
    position: 0,
    direction: 'next',
    sliding: false,
  })

  React.useEffect(() => {
    const handler = () =>
      setSlideState({
        ...slideState,
        cardsToDisplay: breakPointResolver(),
      })
    window.addEventListener('resize', handler)
    const cardsToDisplay = breakPointResolver()
    if (slideState.cardsToDisplay !== cardsToDisplay) {
      setSlideState({ ...slideState, cardsToDisplay })
    }
    return () => window.removeEventListener('resize', handler)
  }, [slideState])

  const getOrder = (itemIndex: number) => {
    const numItems = React.Children.count(children) || 1
    if (itemIndex - slideState.position < 0) {
      return numItems - Math.abs(itemIndex - slideState.position)
    }
    return itemIndex - slideState.position
  }

  const numItems = React.Children.count(children) || 1

  const doSliding = (
    direction: typeof slideState['direction'],
    position: typeof slideState['position']
  ) => {
    setSlideState({
      ...slideState,
      sliding: true,
      direction,
      position,
    })

    setTimeout(() => {
      setSlideState({
        ...slideState,
        direction,
        position,
        sliding: false,
      })
    }, 50)
  }

  const nextSlide = () => {
    doSliding(
      'next',
      slideState.position === numItems - 1 ? 0 : slideState.position + 1
    )
  }

  const prevSlide = () => {
    doSliding(
      'prev',
      slideState.position === 0 ? numItems - 1 : slideState.position - 1
    )
  }

  const renderChildren = React.Children.map(children, (child, i) => (
    <CarouselSlot
      spacing={spacing}
      cardsToDisplay={slideState.cardsToDisplay}
      key={i}
      order={getOrder(i)}
    >
      {child}
    </CarouselSlot>
  ))

  const handleSwipe = (swipeData: EventData) => {
    if (swipeData.dir === 'Left') {
      nextSlide()
    } else {
      prevSlide()
    }
  }
  const handlers = useSwipeable({ onSwiped: handleSwipe })
  return (
    <Wrapper {...props} {...handlers}>
      <NextIconWrapper onClick={nextSlide}>
        <Icon
          icon="arrow-right"
          size={100}
          color="white"
          style={{ filter: 'drop-shadow( 0px 0px 20px rgba(0, 0, 0, .7))' }}
        />
      </NextIconWrapper>
      <CarouselContainer {...slideState} spacing={spacing}>
        {renderChildren}
      </CarouselContainer>
    </Wrapper>
  )
}

export default Carousel
